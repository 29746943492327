<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">订单管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">退款申请列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:10px;">
            <div title="订单编号" class="searchboxItem ci-full">
              <span class="itemLabel">订单编号:</span>
              <el-input v-model="searchForm.orderSn" type="text" size="small" clearable placeholder="请输入课程名称">
              </el-input>
            </div>
            <div title="退款单号" class="searchboxItem ci-full">
              <span class="itemLabel">退款单号:</span>
              <el-input v-model="searchForm.refundSn" type="text" size="small" clearable placeholder="请输入课程名称">
              </el-input>
            </div>
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input v-model="searchForm.productName" type="text" size="small" clearable placeholder="请输入课程名称">
              </el-input>
            </div>
            <div title="退款申请日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 150px;">退款申请日期:</span>
              <el-date-picker clearable size="small" v-model="searchForm.timeData" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 130px;">审核状态:</span>
              <el-select v-model="searchForm.refundStatus" placeholder="请选择审核状态" size="small" clearable>
                <el-option v-for="item in refundStatusList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
              <el-table-column label="订单编号" align="left" prop="orderSn" show-overflow-tooltip width="160" />
              <el-table-column label="退款单号" align="left" prop="refundSn" show-overflow-tooltip width="160" />
              <el-table-column label="课程名称" align="left" prop="productName" show-overflow-tooltip width="200" />
              <el-table-column label="订单金额" align="left" prop="orderMoney" show-overflow-tooltip width="100" />
              <el-table-column label="退款金额" align="left" prop="refundAmount" show-overflow-tooltip width="100" />
              <el-table-column label="退款人数" align="left" prop="refundCnt" show-overflow-tooltip width="100" />
              <el-table-column label="审核状态" align="left" prop="refundStatus" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.refundStatus == 0">新建</span>
                  <span v-else-if="scope.row.refundStatus == 1">成功</span>
                  <span v-else-if="scope.row.refundStatus == 2">失败</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="驳回理由" align="left" prop="refundRemarks" show-overflow-tooltip min-width="150" />
              <el-table-column label="申请退款时间" align="left" prop="refundTime" show-overflow-tooltip width="140" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">
                <template slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="revokeApplyFor(scope.row)" :disabled="scope.row.refundStatus != 0">撤销</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "courseCenterInfo_refundApplicationList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据
      searchForm: {
        orderSn: "", // 订单编号
        refundSn: "", // 退款单号
        productName: "", // 课程名称
        timeData: "", // 退款申请日期
        refundStatus: "", // 审核状态
      },
      refundStatusList: [
        {
          value: "0",
          label: "新建"
        },
        {
          value: "1",
          label: "成功"
        },
        {
          value: "2",
          label: "失败"
        }
      ]
    };
  },
  computed: {},
  created() { },
  methods: {
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.searchForm
      };
      if (this.searchForm.timeData) {
        params.refundBeginTime = this.searchForm.timeData[0];
        params.refundEndTime = this.searchForm.timeData[1];
      }
      this.doFetch({
        url: "/order/drp-order-refund/queryRefundListHospital",
        params,
        pageNum,
      }, true, 2);
    },
    // 撤销
    revokeApplyFor(row) {
      this.$post("/order/drp-order-refund/delete", {
        refundId: row.refundId
      }, 3000, true, 2).then(res => {
        this.$message({
          type: "success",
          message: "操作成功",
        });
        this.getData(this.pageNum);
      }
      );
    },
  },
};
</script>
<style lang="less" scoped></style>